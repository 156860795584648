import { ReactNode } from 'react'
import { CardComponent } from './styles'

interface LargeCardHomeItemProps{
    icon?: string
    label?: string
    num?: number | string
    children?: ReactNode
}

export function LargeCardHomeItem({icon, label, num, children}: LargeCardHomeItemProps){
    return (
        <CardComponent>
            {children} 
        </CardComponent>
    )
}