import ApiServer from "../services/api"
import { fetchToken } from "./firebase"

export const insertOrderValuesIntoEstoque = (data) => {
    const arrayOrdenacao = [6, 2, 1, 5, 3, 4]
    const empresasWithOrder = data.map((item: any, index: number) => {
        item.order = arrayOrdenacao[index]
        return item
    })
    const empresasOrdenadas = empresasWithOrder.sort(function(a,b) {
        return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
    });
    return empresasOrdenadas
}

export const formatNumberToMilhar = (num) => {
    const numberFormat = new Intl.NumberFormat('pt-BR');
    return numberFormat.format(num)
}

export const formatDateToDefault = (date) => {
    // Get year, month, and day part from the date
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    return year + "-" + month + "-" + day;
}

export const formatDateYearAndMonth = (currentDate) => {
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let year = currentDate.getFullYear().toString();
    let formattedDate = `${year}/${month}`;
    return formattedDate;
}

export const toSlug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // Remove espaços em branco do início e do final da string
    str = removerAcentos(str)
    str = str.toLowerCase(); // Converte todos os caracteres para minúsculas
    str = str.replace(/[^a-z0-9 -]/g, '') // Remove caracteres especiais, exceto hífens e espaços
        .replace(/\s+/g, '-') // Substitui espaços por hífens
        .replace(/-+/g, '-'); // Remove hífens duplicados
    return str; // Retorna a string convertida em slug
}

const removerAcentos = (texto) => {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

 export const isFutureMonth = (date) => {
    const now = new Date();
    return (
      date.getFullYear() > now.getFullYear() ||
      (date.getFullYear() === now.getFullYear() &&
        date.getMonth() >= now.getMonth())
    );
  };

 export const isPastMonth = (date) => {
    const now = new Date();
    return (
      date.getFullYear() < now.getFullYear() ||
      (date.getFullYear() === now.getFullYear() &&
        date.getMonth() < now.getMonth())
    );
  };

  export async function getByDate(dataFiltro: Date, getAllIndicatorsAgendamentos, getAllIndicatorsReceitas, setlastDateFilter, setDataFiltro, versao) {
      
      let dataFiltroTratada: Date = new Date(dataFiltro.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"}));
      
      dataFiltroTratada = new Date(dataFiltroTratada.setDate(10));

      ApiServer.log('Página Principal', `Filtrando por data: ${dataFiltroTratada}`, '', versao);

    const resultReceitas = getAllIndicatorsReceitas(dataFiltroTratada);
    const resultAgendamentos = getAllIndicatorsAgendamentos(dataFiltroTratada);
    
    setlastDateFilter(dataFiltroTratada)
    setDataFiltro(dataFiltroTratada)
    // setDataRelatorio(dataFiltro)
    return {
            dataReceitas: resultReceitas,
            dataAgendamentos: resultAgendamentos,
        }
  }

export const goBack = (pagina, location, navigate) => {
    if(pagina.unidade){
        ApiServer.log(`Tela Receitas - ${pagina.marca} - ${pagina.regional} - ${pagina.unidade}`, `Clicou no botão voltar`, 'Voltar para a tela anterior');
        navigate(`/Receitas/${pagina.marca}/${pagina.regional}/${pagina.unidade}`, {
            replace: true
        })
        return;
    }
    if(pagina.regional){
        ApiServer.log(`Tela Receitas - ${pagina.marca} - ${pagina.regional}`, `Clicou no botão voltar`, 'Voltar para a tela anterior');
        navigate(`/Receitas/${pagina.marca}/${pagina.regional}`, {
            replace: true
        })
        return;
    }
    if(pagina.marca){
        ApiServer.log(`Tela Receitas - ${pagina.marca}`, `Clicou no botão voltar`, 'Voltar para a tela anterior');
        navigate(`/Receitas/${pagina.marca}`, {
            replace: true
        })
        return;
    }
    if(location.pathname){
        ApiServer.log(`Tela Receitas`, `Clicou no botão voltar`, 'Voltar para a tela anterior');
        navigate(`/Receitas`, {
            replace: true
        })
        return;
    }
}

export const ordenaDadosReceitas = (arr) => {
    return arr.sort(function(a,b) {
        return b.ordem > a.ordem ? -1 : b.ordem < a.ordem ? 1 : 0;
    })
}

export const ordenarArray = (arr, propriedade) => {
    return arr.sort((a, b) => a[propriedade] > b[propriedade] ? 1 : -1);
}

export const getVersion = () => {
    const jsonValue = localStorage.getItem('@NewIndicadorComercioApp');
    const data = jsonValue != null ? JSON.parse(jsonValue) : null;
    return data && data.versaoAtual
}
  export const requestPermissionNotification = (token) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        ApiServer.log(`Notification permission granted.`, `${JSON.stringify(permission)}`, '' );


        fetchToken(token);

      }
      else
      {
        console.log('Notification permission NOT granted.');
        ApiServer.log(`Notification permission NOT granted.`, '-', '' );
      }
    });
  }
