import styled from "styled-components";
import { colors, DefaultColors } from "../../utils/colors";



export interface IconProps {
  color?: string;
}

export const Container = styled.div` 
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 23rem;
`;

export const ContainerChildren = styled.div`
padding: 1rem;
display: flex;
width: 100%;
justify-content: center;
align-items: center;
flex-direction: row;
// background-color: ${DefaultColors.backgroundColor};
`

export const InfoLastUpdate = styled.span`
color: #c6c6c6;
text-align: right;
font-weight: bold;
font-size: 0.8rem;
margin-right: 0.5rem;
`;


export const GroupedCard = styled.div`
  /* Auto layout */

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 5px;
  padding-bottom: 10px;
  gap: 10px;
`
export const CardTile = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.24px;

  color: #FFFFFF;
`
export const CardSubTitle = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.24px;

  /* Label Color/Dark/Primary */

  color: #FFFFFF;
`

export const CardRowContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 358px;
  gap:10px;
`
export const MainCardIndicatorContainer = styled.div`
  width: calc(33% - 6px);
  cursor: pointer;
`

export const LargeCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 358px;
  height: 100px;

  border: 1px dashed #4472C4;
  border-radius: 8px;

  &.estoque{
    gap: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &.leads-digitais{
    flex-direction: column;
  }

`

export const LargeCardHome = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 358px;
  border: 1px dashed #4472C4;
  border-radius: 8px;
`

export const CardColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  margin: 0;
  gap: 8px;
`

export const CardColumnData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 5px 0 0 0;
  gap: 8px;
`

export const CardColumnValues = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  padding: 0px;
  width: 50%;
  gap: 3px;
`
export const CardColumnAmount = styled.div`
  /* Auto layout */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;  
  margin-left: 15px;
  margin-right: 5px;
`
export const CardRowMedia = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: row; 
  justify-content: center;
  gap: 1.2rem
`
export const CardColumnMedia = styled.div`
  /* Auto layout */
  display: flex; 
  flex-direction: row; 
  gap: 8px;
  justify-content: flex-end;
  width: 50%;
`
export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
`

export const CardRowColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
`

export const CardRowData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`

export const TextTitleCard = styled.div`
  font-size: 14px;
  margin-left: 5px;
  color: #FFFFFF;
  font-weight: 600;
  span{
    display: block;
    font-size: 10px;
    text-transform: uppercase;
  }
`

export const TextCurrency = styled.span`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 23px;

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.408px;
font-feature-settings: 'case' on;

color: #FFFFFF;
`
export const TitleAmount = styled.span`
color: ${colors.blue_100};
font-weight: 600;
font-size: 14px;
text-transform: uppercase;
width: 100%;
margin-right: 5px;
`
export const TextAmount = styled.span`
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
letter-spacing: -1px;
color: #FFFFFF;
`
export const TextAmountTotal = styled.span`
font-style: normal;
font-weight: 500;
font-size: 28px;
line-height: 42px;
color: #FFFFFF;
letter-spacing: -1px;
`
export const CardDetailsContainer = styled.div`
 display: flex;
 justify-content: flex-end;
 align-items: center;
 width: 100%
`

export const CardContainer = styled.div` 
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

export const AccordionTitleArrow = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;