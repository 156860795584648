import { useNavigate } from 'react-router-dom'
import ApiServer from '../../services/api'

import { convertValue, getLogo, tipeInfo } from '../../services/utils'
import { requestPermissionNotification, toSlug } from '../../utils/fn'
import {CardComponent, CardEstoqueSmallComponent, SmallText, Value, ValueComponent} from './styles'
import { Box } from '@chakra-ui/react'

interface CardEstoqueProps{
    icon: string
    label: string
    num: number | string
    sufix?: string
    prefix?: string
}

export interface CardEstoqueSmallProps {
    title: string
    value: number
    cobertura: number
    icon?: any
    isTokenFound?: boolean
}

export function CardEstoque({icon, label, num, sufix, prefix}: CardEstoqueProps){
    return (
        <CardComponent>
            <figure>
                <img src={icon} alt={label} title={label} style={{ width: "100%", height:"23px"}}/>
            </figure>
            <span className='label'>{label}</span>
            <span className='number'>
                {/* {prefix && <span className='prefix'>{prefix}</span>} */}
                <span>{num}</span>
                {sufix && <span className='sufix'>{sufix}</span>}
            </span>
        </CardComponent>
    )
}

export function CardEstoqueSmall({ title, cobertura, value = 0, icon, isTokenFound }: CardEstoqueSmallProps) {
    const slug = toSlug(title)

    const navigate = useNavigate();

    function handleNavigate(route: string) {
        ApiServer.log(`Página Estoque`, `Clicou no card ${title}`, '' );
        requestPermissionNotification(isTokenFound)
        navigate(route, { replace: true})
      }
      
    return (
        <CardEstoqueSmallComponent className="card-default card-estoque" onClick={()=>handleNavigate(`Estoque/${slug}`)}>
            {/* <Label>Valor</Label> */}
            <div>
                {icon && icon ?
                    <figure>
                        <img src={getLogo(icon)} title={title} alt={title} />
                    </figure>
                : 
                    <Box height={8}></Box>
                }
            </div>
            
            <h4>{title}</h4>
            
            <span>
                {/* <Label>Cobertura</Label> */}
                <ValueComponent>
                    <Value>{convertValue(cobertura)}</Value>
                    <SmallText>dias</SmallText>
                </ValueComponent>
            </span>

            <span>
                {/* <Label>Valor</Label> */}
                <ValueComponent>
                    <Value>{convertValue(value)}</Value>
                    <SmallText>{tipeInfo(value)}</SmallText>
                </ValueComponent>
            </span>

        </CardEstoqueSmallComponent>
    );
}