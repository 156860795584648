import { Box } from "@chakra-ui/react";
import { convertValue, tipeInfo } from "../../services/utils";

interface CardMarcasProps{
    label: string,
    icon: any,
    total: number,
}

export default function CardMarcas({label, icon, total}: CardMarcasProps){
    return (
        <div className="card-marca">
            {icon && icon ?
                <figure>
                    <img src={icon} title={label} alt={label} />
                </figure>
            : 
                <Box height={8}></Box>
            }
            <h4>{label}</h4>
            <span>{convertValue(Number(total))}{tipeInfo(Number(total))}</span>
        </div>
    )
}