import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { fetchToken, onMessageListener} from './utils/firebase';
import ApiServer from './services/api';

require('react-web-vector-icons/fonts');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);


  fetchToken();

  onMessageListener().then(payload => {
    
    console.log(payload);

    ApiServer.log(`Notificação`, `${JSON.stringify({title: payload.notification.title, body: payload?.notification?.body})}`, '' );

  }).catch(err => {
    console.log('failed: ', err);
    ApiServer.log(`Failed`, `${JSON.stringify(err)}`, '' );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
