import {Route, Routes} from "react-router-dom";

import Login from '../pages/Login';
import ChangePassWord from '../pages/ChangePassWord';

export default function AuthStack() {

  return (
    <Routes >
        <Route path="/ChangePassWord" element={<ChangePassWord/>} />
        <Route path="/"  element={<Login />}/>
    </Routes>

  );
}