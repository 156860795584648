import styled from "styled-components";
import { AppStateContext } from "../contexts/DataContext";
import { useContext } from "react";
import { getVersion } from "../utils/fn";
import { Box } from "@chakra-ui/react";


export default function Versao(){
    const {
        versao,
    } = useContext(AppStateContext);

    const versionStorage = getVersion()
    
    return versionStorage ? <TextVersion>{versao === '' ? versionStorage : versao}.v</TextVersion> : <Box h="12px"></Box>
}

export const TextVersion = styled.span`
  width: 100%;
  font-size: 0.5rem;
  color: #c6c6c6;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
`;