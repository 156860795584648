import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Main from '../pages/Main';

// import Receitas from '../pages/Receitas';
// import DetailsIndicator from '../pages/DetailsIndicator';
// import OnBoard from '../pages/OnBoard';
// import Details from '../pages/Details';
// import DashBoard from '../pages/DashBoard';

import LoadingScreen from '../pages/LoadingScreen';

const Receitas = lazy(()=> import("../pages/Receitas"))
const ReceitasMarcas = lazy(()=> import("../pages/Receitas/receitasMarcas"))
const ReceitasRegional = lazy(()=> import("../pages/Receitas/receitasRegional"))
const ReceitasUnidade = lazy(()=> import("../pages/Receitas/receitasUnidade"))
const ReceitasGrupoDetalhes = lazy(()=> import("../pages/Receitas/receitasGrupoDetalhes"))

// const DetailsIndicator = lazy(()=> import("../pages/DetailsIndicator"))
// const OnBoard = lazy(()=> import("../pages/OnBoard"))
// const Details = lazy(()=> import("../pages/Details"))
// const DashBoard = lazy(()=> import("../pages/DashBoard"))

const Estoque = lazy(()=> import("../pages/Estoque"))
const EstoqueMarca = lazy(()=> import("../pages/Estoque/estoqueMarca"))
const EstoqueRegional = lazy(()=> import("../pages/Estoque/estoqueRegional"))
const EstoqueLoja = lazy(()=> import("../pages/Estoque/estoqueLoja"))

const Agendamentos = lazy(()=> import("../pages/Agendamentos"));
const AgendamentosMarca = lazy(()=> import("../pages/Agendamentos/agendamentosMarca"))
const AgendamentosRegional = lazy(()=> import("../pages/Agendamentos/agendamentosRegional"))

export default function AppRoute() {
  return (
      <Routes>
          <Route path="/" element={<Main/>} />

          <Route path="/Receitas" element={
            <Suspense fallback={<LoadingScreen />}>
              <Receitas />
            </Suspense>
          } />

          <Route path="/Receitas/grupo/:grupo" element={
            <Suspense fallback={<LoadingScreen />}>
              <ReceitasGrupoDetalhes />
            </Suspense>
          } />

          <Route path="/Receitas/:marca" element={
            <Suspense fallback={<LoadingScreen />}>
              <ReceitasMarcas />
            </Suspense>
          } />

          <Route path="/Receitas/:marca/grupo/:grupo" element={
            <Suspense fallback={<LoadingScreen />}>
              <ReceitasGrupoDetalhes />
            </Suspense>
          } />

          <Route path="/Receitas/:marca/:regional" element={
            <Suspense fallback={<LoadingScreen />}>
              <ReceitasRegional />
            </Suspense>
          } />

          <Route path="/Receitas/:marca/:regional/grupo/:grupo" element={
            <Suspense fallback={<LoadingScreen />}>
              <ReceitasGrupoDetalhes />
            </Suspense>
          } />

          <Route path="/Receitas/:marca/:regional/:unidade" element={
            <Suspense fallback={<LoadingScreen />}>
              <ReceitasUnidade />
            </Suspense>
          } />

          <Route path="/Receitas/:marca/:regional/:unidade/grupo/:grupo" element={
            <Suspense fallback={<LoadingScreen />}>
              <ReceitasGrupoDetalhes />
            </Suspense>
          } />

          {/* <Route path="/DetailsIndicator" element={
            <Suspense fallback={<LoadingScreen />}>
              <DetailsIndicator />
            </Suspense>
          } />

          <Route path="/OnBoard"  element={
            <Suspense fallback={<LoadingScreen />}>
              <OnBoard />
            </Suspense>
          }/>

          <Route path="/DashBoard"  element={
            <Suspense fallback={<LoadingScreen />}>
              <DashBoard />
            </Suspense>
          }/>

          <Route path="/DashBoard/Details"  element={
            <Suspense fallback={<LoadingScreen />}>
              <Details />
            </Suspense>
          }/> */}

          <Route path="/Estoque" element={
            <Suspense fallback={<LoadingScreen />}>
              <Estoque />
            </Suspense>
          } />

          <Route path="/Estoque/:marca" element={
            <Suspense fallback={<LoadingScreen />}>
              <EstoqueMarca />
            </Suspense>
          } />

          <Route path="/Estoque/:marca/:regional" element={
            <Suspense fallback={<LoadingScreen />}>
              <EstoqueRegional />
            </Suspense>
          } />

          <Route path="/Estoque/:marca/:regional/:loja" element={<EstoqueLoja/>} />

          <Route path="/loading" element={<LoadingScreen/>} />
          
          <Route path="/Agendamentos" element={
            <Suspense fallback={<LoadingScreen />}>
              <Agendamentos />
            </Suspense>
            } />

          <Route path="/Agendamentos/:marca" element={
            <Suspense fallback={<LoadingScreen />}>
              <AgendamentosMarca />
            </Suspense>
          } />

          <Route path="/Agendamentos/:marca/:regional" element={
            <Suspense fallback={<LoadingScreen />}>
              <AgendamentosRegional />
          </Suspense>}
          />
      </Routes>
  );
}
