export const colors = {
    blue_100: '#4472C4',
    blue_500: '#0D3D8C',
    blue_700: '#1E327F',
    red: '#FB6C6C',     
    green: '#34C759',
    yellow: '#F9E744',
}
export const DefaultColors = {
    backgroundColor : '#1E327F',
    //backgroundColor: `radial-gradient(circle, rgba(1,75,166,1) 0%, rgba(0,75,153,1) 100%)`,
    //backgroundColor: `rgb(1,75,166)`,
    label: '#FFFFFF',
    detaisLabel: '#409cff',
    buttonBgColor: '#0154A2',
    borderColor: colors.blue_100,
}