import styled from "styled-components"
import { colors, DefaultColors } from "../../utils/colors"

export const CardComponent = styled.div`
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
    align-items: center;
    width: calc(100% / 3);
    //border: 1px dashed ${DefaultColors.borderColor};

    &:nth-child(2){
        position: relative;
        &:after{
            content: "";
            border-right: 1px dashed ${DefaultColors.borderColor};
            height: calc(100% - 20px);
            width: 1px;
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 0;
        }
        &:before{
            content: "";
            border-left: 1px dashed ${DefaultColors.borderColor};
            height: calc(100% - 20px);
            width: 1px;
            display: inline-block;
            position: absolute;
            top: 10px;
            left: 0;
        }
        //border-left: 1px dashed ${DefaultColors.borderColor};
    }

    span{
        &.label{
            color: ${colors.blue_100};
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            margin: 5px 0;
        }
        &.number{
            color: #FFFFFF;
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            margin: 0;
            position: relative;
            span{
                line-height: 20px;
            }
            .prefix, .sufix{
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                font-weight: 500;
                margin: 0 3px;
            }
            
        }
        &.index{
            font-weight: 400;
            font-size: 14px;
            color: ${colors.green};
            position: relative;

            &:after{
                content: "";
                position: relative;
                display: inline-block;
                top: -2px;
                margin-left: 5px;
                width: 0; 
                height: 0; 
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid ${colors.green};
                text-align: center;
            }
            
            &.negative{
                color: ${colors.red};
                &:after{
                    content: "";
                    top: -1px;
                    width: 0; 
                    height: 0; 
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-top: 8px solid ${colors.red};
                    border-bottom: 0;
                }
            }
        }
    }
`

export const Logo = styled.div`
    figure{
        img{
            
        }
    }
`;

export const CardEstoqueSmallComponent = styled.div`
    border-radius: 8px;
    border-width: 1px;
    padding: 15px 10px 5px 10px;
    border-style: dashed;
    border-color: #4472C4;
    border-top-color: #4472C4;
    width: 90px;
    //height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const Title = styled.span`
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: ${colors.blue_100}; 
    width: 90px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
    margin: 5px 0;
`;

export const DataValues = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
`;
export const Value = styled.span`
    font-size: 18px;
    letter-spacing: -1px;
    font-weight: 500;
    line-height: 18px;
    color:  #FFFFFF;
    margin: 0 3px;
`
export const SmallText = styled.span`
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: #FFF;
`

export const Label = styled.span`
    color: #FFFFFF;
    font-size: 12px;
`

export const ValueComponent = styled.span`
    color: #FFFFFF;
    font-size: 14px;
`

export const DataValuesCobertura = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
