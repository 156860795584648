import styled from 'styled-components'

export const Content = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => props.color};
    height: 100%;

`;

 

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    background-color: ${props => props.color};
    padding: 0 10px
`;
 
export const ContainerButton = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    padding-top: 1rem;
    width: 100%;
    background-color: ${props => props.color};
`
 
export const ButtonRoute = styled.div`

    padding: 10px;
    font-size: 1.1rem;
    color: #FFF;
    cursor: pointer

`
