import { VStack, Text } from "@chakra-ui/react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Loading(){
    return (
        <VStack textAlign="center">
            <AiOutlineLoading3Quarters color="#FFFFFF" opacity={0.8} size={16} className="rotating" />
            {/* <Text color="#FFFFFF" opacity={0.5}>Carregando...</Text> */}
        </VStack>
    )
}