import React, { useContext, useEffect } from 'react';


import AuthStack from './auth.routes';
import { AuthContext } from '../contexts/AuthProvider';
import AppRoutes from './app.routes';
import styled from 'styled-components';
import PullToRefresh from 'react-simple-pull-to-refresh';

const Routes: React.FC = () => {
    const { authenticated } = useContext(AuthContext);
    
    function handleRefresh(): Promise<any> {
      return  new Promise((resolveOuter) => {
        resolveOuter(
            new Promise(async (resolveInner) => {
                setTimeout(resolveInner, 1000);
                window.location.reload()
            })
        );
    })
}

useEffect(() => {
    document.body.classList.add('transition-run');
    return () => {
      document.body.classList.remove('transition-run');
    };
  }, []);


return authenticated ?
    <ViewContainer>
            <PullToRefresh onRefresh={handleRefresh} canFetchMore={true} className='pullToRefresh'
            >
                <AppRoutes />
            </PullToRefresh>
    </ViewContainer>
    :
    <AuthStack />;
};

export const ViewContainer = styled.div`
    height: 100%!important
`
export default Routes;
