import { Box } from "@chakra-ui/react"
import { convertValue, tipeInfo } from "../../services/utils"

interface AmountValueProps{
    value: number
    classname?: string
    bold?: boolean
}

export default function Amountvalue({value, classname, bold}: AmountValueProps){
    return (
        <Box className={`amount-value ${classname} ${bold ? 'bolder' : ''}`}>
            <span className="amount-value__currency">R$</span>
            <span className="amount-value__value">{convertValue(value)}</span>
            <span className="amount-value__milhar">{tipeInfo(value)}</span>
        </Box>
    )
}