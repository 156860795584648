import { createContext, useEffect, useRef, useState } from 'react';

import ApiServer from '../services/api';
import { notify } from '../utils/toast-info';

interface StateProviders {
    setAuthenticated,
    authenticated: boolean,
    authLoading: boolean,
    handleLogin: (email: string, password: string) => void,
    logoff: () => void,
}

const jsonAuthValue = localStorage.getItem('@autenticatedLogin:AnalyticsCOM');
const authData = jsonAuthValue != null ? JSON.parse(jsonAuthValue) : null;


export const AuthContext = createContext({} as StateProviders);

export function AuthProvider({ children }) {
    
    let authInitialState = true
    if(authData){
        ApiServer.setToken(authData.bearerToken);
    }else{
        authInitialState = false
    }
    const [authenticated, setAuthenticated] = useState(authInitialState);
    const [authLoading, setAuthLoading] = useState(false);


    const unMount = useRef(false);
    useEffect(() => {
        const validateAuthenticated = async () => {
            setAuthLoading(true);
            const data = await getData();
            if (data) {
                try {
                    ApiServer.setToken(data.bearerToken);
                    setAuthenticated(true);

                } catch (error) {
                    setAuthenticated(false);
                    setAuthLoading(false);
                }
            } else {
                setAuthenticated(false);
            }
            setAuthLoading(false);
        };



        if (!unMount.current) {
            validateAuthenticated();
        }

        return () => {
            unMount.current = true;
        }; 
    });

    const storeData = async (value: any) => {
        try {
            const jsonValue = JSON.stringify(value);
            await localStorage.setItem('@autenticatedLogin:AnalyticsCOM', jsonValue);
        } catch (e) {
        }
    };

    const getData = async () => {
        try {
            const jsonValue = await localStorage.getItem('@autenticatedLogin:AnalyticsCOM');
            return jsonValue != null ? JSON.parse(jsonValue) : null;
        } catch (e) {
        }
    };

    

    const handleLogin = async (email: string, password: string) => {
        
        if (!email) {
            notify('Favor digitar o email.');
            return;
        }

        if (!password) {
            notify('Favor digitar a senha.');
            return;
        }

        try {
            setAuthLoading(true);
            const login = await ApiServer.login(email.trim(), password.trim());
            if (login?.data?.active) {
                await ApiServer.setToken(login?.data?.bearerToken);

                await storeData(login?.data);
                setAuthenticated(true);
            } else {
                notify(`ERRO: ${login?.error}`);
                ApiServer.log('Aplicativo', 'Login', 'Erro_login: Usuário Inativo');
                setAuthenticated(false);
            }

            setAuthLoading(false);
        } catch (error) { 
            ApiServer.log('Aplicativo', 'Login', 'Erro_login:' + 'Usuário inválido.');
            notify('Usuário inválido.')
            setAuthenticated(false);
            setAuthLoading(false);
        }

    };

    const logoff = async () => {

        setAuthLoading(true);
        await ApiServer.logoff();
        await localStorage.removeItem('@autenticatedLogin:AnalyticsCOM');
        setAuthenticated(false);
        setAuthLoading(false);

    };

    return (
        <AuthContext.Provider
            value={{ authenticated, authLoading, handleLogin, logoff, setAuthenticated }}
        >
            {children}
        </AuthContext.Provider>
    );
}