import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardMarcas from "../CardMarcas";
import { getLogo } from "../../services/utils";
import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { toSlug } from "../../utils/fn";

export default function Carousel({content}){

    
        return (
            <Flex className="slider">

                {content?.map(item => {
                    return (
                        <Link to={`/Receitas/${toSlug(item.empresa)}`} key={item.empresa}>
                            <CardMarcas key={item.empresa} label={item.empresa} icon={getLogo(item.empresa)} total={item.total} />
                        </Link>
                    )
                })}
                </Flex>
        )
}