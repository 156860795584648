/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FormControl, Heading, Input } from '@chakra-ui/react';
import React, { FormEvent, useContext, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthProvider';
import { AppStateContext } from '../../contexts/DataContext';
import ApiServer from '../../services/api';
import { DefaultColors } from '../../utils/colors';
import { ButtonRoute, Container, ContainerButton, Content } from './styles';
const colorText = DefaultColors.label;
const colorBackGround = DefaultColors.backgroundColor;
export default function Login() {
    const [email, setEmail] = React.useState('');
    const [passWord, SetPassWord] = React.useState('');

    const {  versao } = useContext(AppStateContext)
    const { handleLogin, authenticated } = useContext(AuthContext);

    const handleAutenticate = async (e: FormEvent) => {
        e.preventDefault();

        handleLogin(email, passWord);
    }
    useEffect(() => {
        if(authenticated)
            ApiServer.log('Login', 'Navegação', '', versao );
    }, [])

    return (
        <Content onSubmit={handleAutenticate} color={colorBackGround}>
                
                <Container>
                    <Heading paddingBottom={5} color={colorText} >Bem vindo!</Heading>
                    <FormControl  >
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' type='email' backgroundColor={colorText}/>
                    </FormControl>
                    <FormControl paddingTop={5} >
                        <Input value={passWord}  onChange={(e) => SetPassWord(e.target.value)} placeholder='Senha' type='password' backgroundColor={colorText}  />
                    </FormControl>
                    <ButtonRoute   color={colorText}   >
                        <Link to={`/ChangePassWord`}>Altera Senha</Link>
                    </ButtonRoute>
                    <ContainerButton   >
                      <Button  type='submit' width={'100%'}  borderRadius={8} colorScheme='blue'>Confirmar</Button>
                    </ContainerButton>
                </Container>
                
        </Content>
    )
}
    