/* eslint-disable react-hooks/exhaustive-deps */
import {  Heading, FormControl, Input, Button } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { AppStateContext } from '../../contexts/DataContext';
import ApiServer from '../../services/api';
import { DefaultColors } from '../../utils/colors';
import {Container , Content, ContainerButton } from './styles';


export default function ChangePassWord() {
    const [email, setEmail] = React.useState('');
    const [passWord, SetPassWord] = React.useState('');
    const [NewPassWord, setNewPassWord] = React.useState('');

    const navigate = useNavigate();
    const { handleNewPassword, versao } = useContext(AppStateContext);
 
    const changePassword = async () => {
        await handleNewPassword(email, passWord, NewPassWord);
        navigate('/', { replace: true})
    };

    useEffect(() => {
        ApiServer.log('ChangePassWord', 'Navegação', '', versao );
    }, [])

    return (
        <Content   color={DefaultColors.backgroundColor}>
            <Header title='Redefinição de senha'  route='/' />
                <Container>
                    <Heading paddingBottom={5} color={DefaultColors.label} >Informe os dados</Heading>
                    <FormControl  >
                        <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' type='email' backgroundColor={DefaultColors.label}/>
                    </FormControl>
                    <FormControl paddingTop={5} >
                        <Input value={passWord}  onChange={(e) => SetPassWord(e.target.value)} placeholder='Senha atual' type='password' backgroundColor={DefaultColors.label}  />
                    </FormControl>
                    <FormControl paddingTop={5} >
                        <Input value={NewPassWord}  onChange={(e) => setNewPassWord(e.target.value)} placeholder='Nova senha' type='password' backgroundColor={DefaultColors.label}  />
                    </FormControl>
                    <ContainerButton   >
                        <Button onClick={() => changePassword()} type='submit'  width={'100%'}    borderRadius={8} colorScheme='blue'>Confirmar</Button>
                        <Button onClick={() => navigate('/')} type='submit'  width={'100%'}  borderRadius={8} colorScheme='blue'>Voltar</Button>
                    </ContainerButton>
                </Container>
               
        </Content>
    );
}
 