/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppStateContext } from '../../contexts/DataContext';
import ApiServer from '../../services/api';
import Header from '../../components/Header';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import {
  AccordionTitleArrow,
  CardRow,
  CardTile,
  Container,
  ContainerChildren,
  GroupedCard,
  LargeCard,
  LargeCardHome,
  } from './styles';
import { registerLocale } from "react-datepicker";
//import { AuthContext } from '../../contexts/AuthProvider';
import ptBr from "date-fns/locale/pt-BR"; // the locale you want
import { convertValue } from '../../services/utils';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CardEstoque, CardEstoqueSmall } from '../../components/CardEstoque/CardEstoque';
import { formatNumberToMilhar, getByDate, requestPermissionNotification } from '../../utils/fn';
import { LargeCardHomeItem } from '../../components/LargeCardHomeItem/LargeCardHomeItem';
import { BsGraphUp } from "react-icons/bs";
import Carousel from '../../components/Carousel';
import AmountValue from '../../components/AmountValue';
import CalendarFilter from '../../components/CalendarFilter/indext';
import icon_estoque from '../../assets/icones/icon-estoque.svg'
import icon_valor from '../../assets/icones/icon-valor.svg'
import icon_cobertura from '../../assets/icones/icon-cobertura.svg'
import Versao from '../../components/Versao';

registerLocale("ptBr", ptBr); // register it with the name you want

export type FilterValue = 'Receitas' | 'Vendas';


export default function Main() {

  const [accordionItemVisible, setAccordionItemVisible] = useState(false)
  
  const location = useLocation();
  const navigate = useNavigate();
  const {
    dadosIndicadorReceitas,
    dadosIndicadorEstoque,
    dadosIndicadorAgendamentos,
    setlastDateFilter,
    versao,
    isTokenFound,
  } = useContext(AppStateContext);
 
  useEffect(() => {
    if (location?.state?.dateFilter) {
      setlastDateFilter(location?.state?.dateFilter)
    }
  }, [location])

  const receitasData: any = dadosIndicadorReceitas?.relatorio
  const estoqueData: any = dadosIndicadorEstoque?.relatorio
  const agendamentosData: any = dadosIndicadorAgendamentos?.relatorio

  function handleNavigate(route: string) {
    ApiServer.log(`Página inicial - rota: ${route.replace(/\//g, '')}`, `Clicou no botão MAIS DETALHES de ${route.replace(/\//g, '')}`, '', versao );
    requestPermissionNotification(isTokenFound)
    navigate(route)
  }

  const openCloseAccordionTitle = (value: string) => {
    ApiServer.log('Tela Inicial', `Expandiu/recolheu o módulo ${value}`, 'Expandir ou recolher módulos na Home', versao);
    setAccordionItemVisible(!accordionItemVisible)
  }


  return (
    <>
      <Container>
        <Versao />
        <Header title='Cockpit VP'
          children={
            <ContainerChildren>
              <CalendarFilter getByDate={getByDate}  />
            </ContainerChildren>
          }
        />

        {/* RECEITAS */}
        <GroupedCard>
          <Accordion className="accordion" defaultIndex={[0]} allowMultiple>
            <AccordionItem className={`accordion__item ${accordionItemVisible ? `active` : ''}`}>
              <AccordionButton className='accordion__item--title' onClick={()=>openCloseAccordionTitle("Receitas")}>
                <CardTile>Receitas</CardTile>
                <AccordionTitleArrow className='accordion__item--title-arrow'>
                  <MdOutlineKeyboardArrowDown color='#FFFFFF' size={32} />
                </AccordionTitleArrow>
              </AccordionButton>
              <AccordionPanel className='accordion__item--content'>
                {/* {loadingReceitas ? 
                  <>
                    <Box className='card-default' w="100%" alignItems="center" justifyContent="space-between" >
                      <Flex color="#FFFFFF" alignItems="center" width="50%" opacity={0.2}>
                        <SkeletonCircle size='10' />
                        <Stack ml="10px" width="50%">
                          <SkeletonText noOfLines={2} spacing='2' skeletonHeight='3' />
                        </Stack>
                      </Flex>
                      <Box color="#FFFFFF" width="40%" opacity={0.2}>
                        <SkeletonText noOfLines={1} spacing='2' skeletonHeight='3' />
                      </Box>
                    </Box>
                    <Flex className="slider">
                      <Box className="card-marca">
                        <SkeletonCircle size="8" opacity={0.2} />
                        <SkeletonText noOfLines={2} opacity={0.2} spacing='2' skeletonHeight='3' mt="15px" w="100%" />
                      </Box>
                      <div className="card-marca">
                        <SkeletonCircle size="8" opacity={0.2} />
                        <SkeletonText noOfLines={2} opacity={0.2} spacing='2' skeletonHeight='3' mt="15px" w="100%" />
                      </div>
                      <div className="card-marca">
                        <SkeletonCircle size="8" opacity={0.2} />
                        <SkeletonText noOfLines={2} opacity={0.2} spacing='2' skeletonHeight='3' mt="15px" w="100%" />
                      </div>
                      <div className="card-marca">
                        <SkeletonCircle size="8" opacity={0.2} />
                        <SkeletonText noOfLines={2} opacity={0.2} spacing='2' skeletonHeight='3' mt="15px" w="100%" />
                      </div>
                    </Flex>
                    
                  </>
                : */}
                  <>
                    <Box onClick={() => handleNavigate('/Receitas')} className='card-default' w="100%" alignItems="center" justifyContent="space-between" >
                      <Flex color="#FFFFFF" alignItems="center">
                          <BsGraphUp color='#FFFFFF' size={24} />
                          <Box ml="10px">
                              <Text fontSize="11px" fontWeight="600">TOTAL</Text>
                              <Heading as="h4" fontSize="14px" lineHeight="14px">Divisão Comércio</Heading>
                          </Box>
                      </Flex>
                      <Box color="#FFFFFF">
                        <AmountValue value={receitasData?.total} classname="" />
                      </Box>
                    </Box>
                    {receitasData &&
                      <Carousel content={receitasData?.empresas} />
                    }
                  </>

                {/* } */}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GroupedCard>
              
        {/* ESTOQUE */}
        <GroupedCard>
          <Accordion className="accordion" defaultIndex={[0]} allowMultiple>
            <AccordionItem className={`accordion__item ${accordionItemVisible ? `active` : ''}`}>
              <AccordionButton className='accordion__item--title' onClick={()=>openCloseAccordionTitle("Estoque")}>
                <CardTile>Estoque</CardTile>
                <AccordionTitleArrow className='accordion__item--title-arrow'>
                  <MdOutlineKeyboardArrowDown color='#FFFFFF' size={32} />
                </AccordionTitleArrow>
              </AccordionButton>
              <AccordionPanel className='accordion__item--content'>
                
                {estoqueData &&
                  <>
                    <LargeCard className="estoque card-default" onClick={() => handleNavigate('/Estoque')}>
                      <CardEstoque icon={icon_estoque} label="Estoque" num={formatNumberToMilhar(dadosIndicadorEstoque?.total.estoque)}></CardEstoque>
                      <CardEstoque icon={icon_valor} label="Valor" num={convertValue(dadosIndicadorEstoque?.total.valor)} prefix="R$" sufix="m"></CardEstoque>
                      <CardEstoque icon={icon_cobertura} label="Cobertura" num={dadosIndicadorEstoque.total.cobertura} sufix="dias"></CardEstoque>
                    </LargeCard>

                    <CardRow>
                      <Flex className="slider">
                      {
                        estoqueData.map((_relatorio, index) => {
                          return (
                            <CardEstoqueSmall cobertura={_relatorio.cobertura} icon={_relatorio.empresa} key={index} title={_relatorio.empresa} value={_relatorio.valor} />
                          )
                        })
                      }
                      </Flex>
                    </CardRow>
                  </>
                }
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GroupedCard>

        {/* AGENDAMENTO */}
        <GroupedCard>
          <Accordion className="accordion" defaultIndex={[0]} allowMultiple>
            <AccordionItem className={`accordion__item ${accordionItemVisible ? `active` : ''}`}>
              <AccordionButton className='accordion__item--title' onClick={()=>openCloseAccordionTitle("Agendamentos")}>
                <CardTile>Agendamento</CardTile>
                <AccordionTitleArrow className='accordion__item--title-arrow'>
                  <MdOutlineKeyboardArrowDown color='#FFFFFF' size={32} />
                </AccordionTitleArrow>
              </AccordionButton>
              <AccordionPanel className='accordion__item--content'>
                {agendamentosData?.dados &&
                  <>
                    <LargeCardHome className="agendamentos__home card-default" onClick={() => handleNavigate('/Agendamentos')}>
                      <LargeCardHomeItem>
                        <span className='label'>Agendamento</span>
                        <span className='number'>
                          <span>{formatNumberToMilhar(agendamentosData?.dados.quantidadeAgendamento)}</span>
                        </span>

                        <span className='label'>Realizado</span>
                        <span className='number'>
                            <span>{formatNumberToMilhar(agendamentosData.dados.quantidadeRealizado)}</span>
                            <span>({agendamentosData.dados.percentualRealizado}%)</span>
                        </span>
                      </LargeCardHomeItem>

                      <LargeCardHomeItem>
                        
                        <small className='label'>Ativos - passivos</small>
                        <span className='number'>
                          <span>{agendamentosData.dados.percentualAtivo}% - {agendamentosData.dados.percentualPassivo}%</span>
                        </span>

                        <small className='label'>Passantes</small>
                        <span className='number'>
                            <span>{formatNumberToMilhar(agendamentosData?.dados?.quantidadePassante)}</span>
                        </span>
                      </LargeCardHomeItem>

                    </LargeCardHome>
                
                  </>
                }
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </GroupedCard>


        <Box py="20px">
          <Text color="#FFFFFF" fontSize={10} p={2}>CockpitVP &copy; 2024. </Text>
        </Box>

      </Container>

      </>
  );
}

