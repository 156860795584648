import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import ApiServer from '../services/api';

const firebaseConfig = {
  apiKey: "AIzaSyC2XSMLJKRzNKGt1ZpCL3VH_ZJd0wFbtXs",
  authDomain: "com-cockpitvp.firebaseapp.com",
  projectId: "com-cockpitvp",
  storageBucket: "com-cockpitvp.appspot.com",
  messagingSenderId: "764990529442",
  appId: "1:764990529442:web:98b31b71a225b212b05cfb",
  measurementId: "G-N9P9L9NVTP"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken:any = (setTokenFound?) => {
  return getToken(messaging, {vapidKey: 'BLu4XvIYcruDLh715PrYqJnP2AA1xr7k58m0AkqDpIEqd979A0G2g_D0_xwxicmbQuHnzFFVSikVe63TKDdXNBQ'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      ApiServer.log(`current token for client`, `${currentToken}`, '' );
      // setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      ApiServer.log('No registration token available.', 'No registration token available.', '' );
      console.log('No registration token available. Request permission to generate one.');
      // setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {

    ApiServer.log('No registration token available.', `${JSON.stringify(err)}`, '' );
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener:any = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});