
import styled from "styled-components";
import { DefaultColors } from "../../utils/colors";

import { FiCalendar } from "react-icons/fi";

import DatePicker from "react-datepicker";

export interface IconProps {
  color?: string;
}


export const ContainerCalendar = styled.div`
display: flex; 
align-items: center;
flex-direction: row; 
gap: 5px;
cursor: pointer;
`

export const DateSelect = styled(DatePicker)`
width: 85px;
color: #4472C4;
font-size: 14px;
font-weight: 'bold';
background-color: ${DefaultColors.backgroundColor};
cursor: pointer;
`;


export const IconCalendar = styled(FiCalendar)`
  color: #4472C4;
`;