/* eslint-disable react-hooks/exhaustive-deps */
// CounterContext.tsx
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import ApiServer from '../services/api';

import { AuthContext } from './AuthProvider';
import { notify } from '../utils/toast-info';

import { EstoqueDataProps } from '../types/EstoqueModel';
import { AgendamentosDataProps } from '../types/AgendamentosModel';
import { ReceitasDataProps } from '../types/ReceitaModel';


interface StateProviders {
    dadosIndicadorReceitas?: ReceitasDataProps,
    dadosIndicadorEstoque: EstoqueDataProps,
    dadosIndicadorAgendamentos: AgendamentosDataProps,
    date: Date,
    loading: boolean,
    loadingReceitas: boolean,
    loadingEstoque: boolean,
    loadingAgendamentos: boolean,
    versao: string,
    isTokenFound: boolean,
    handleNewPassword: (email: string, passWord: string, NewPassWord: string) => Promise<void>,
    getAllIndicatorsReceitas: (date?: Date) => Promise<boolean | undefined>,
    getAllIndicatorsEstoque: () => Promise<boolean | undefined>,
    getAllIndicatorsAgendamentos: (date?: Date) => Promise<boolean | undefined>,
    setlastDateFilter
    setTokenFound,
} 

export const AppStateContext = createContext({} as StateProviders);

export function AppStateProvider(props: any) {
    const { authenticated, setAuthenticated } = useContext(AuthContext);

    const [dadosIndicadorReceitas, setDadosIndicadorReceitas] = useState<any>([]);
    const [dadosIndicadorEstoque, setDadosIndicadorEstoque] = useState<any>([]);
    const [dadosIndicadorAgendamentos, setDadosIndicadorAgendamentos] = useState<any>([]);

    const [lastDateFilter, setlastDateFilter] = useState<Date>(new Date())
    const unMount = useRef(false);
    const [loading, setLoading] = useState(false);
    const [versao, setVersao] = useState("");
    
    const [loadingReceitas, setLoadingReceitas] = useState(false);
    const [loadingEstoque, setLoadingEstoque] = useState(false);
    const [loadingAgendamentos, setLoadingAgendamentos] = useState(false);

    const [isTokenFound, setTokenFound] = useState(false);

    useEffect(() => {
        setLastDataStorage()
        window.addEventListener('visibilitychange', function() { 
          if (document.visibilityState === 'visible') {  
            getAllIndicatorsReceitas(lastDateFilter);
            getAllIndicatorsEstoque();
            getAllIndicatorsAgendamentos(lastDateFilter);
        }
    });
}, [])



useEffect(() => {
    if (!unMount.current || authenticated) {
        try {
            const fetchData = async () => {
                     getAllIndicatorsReceitas();
                     getAllIndicatorsEstoque();
                     getAllIndicatorsAgendamentos();
                }
                fetchData();
            } catch (error) {
                setLoading(false);
            }
        }

        return () => {
            unMount.current = true;
        };

    }, [authenticated]);

    const setLastDataStorage = () => {
         
        const lastValuesReceita = getDataStorage('@NewIndicadorComercioApp');
        const lastValuesEstoque = getDataStorage('@NewIndicadorComercioApp:Estoque');
        const lastValuesAgendamentos = getDataStorage('@NewIndicadorComercioApp:Agendamentos');
        
        if (lastValuesEstoque)
            setDadosIndicadorEstoque(lastValuesEstoque);
        if (lastValuesReceita)
            setDadosIndicadorReceitas(lastValuesReceita);
        if (lastValuesAgendamentos)
            setDadosIndicadorAgendamentos(lastValuesAgendamentos);
    }
 
    const getAllIndicatorsReceitas = async (date?: Date) => {
        
        try {
            //setLoadingReceitas(true)
            if (!authenticated) {
                return;
            }
            // const lastValues = getDataStorage('@NewIndicadorComercioApp');
            // if (lastValues)
            //     setDadosIndicadorReceitas(lastValues);

            const data: any = await ApiServer.BuscarDadosGrupoIndicadoresReceitas(date || lastDateFilter);

            if (data) {
                window.clarity("identify", data?.usuarioId);
                setDadosIndicadorReceitas(data);
                setDataStorage('@NewIndicadorComercioApp', data);
                setVersao(data?.versaoAtual)
                setLoadingReceitas(false)
            } else {
                setLoadingReceitas(false)
                return false
            }
            setLoadingReceitas(false)
            return true;
        } catch (error) {
            const dados = getDataStorage('@NewIndicadorComercioApp');
            setDadosIndicadorReceitas(dados);
            setLoadingReceitas(false)
            return false
        }


    };

    const getAllIndicatorsAgendamentos = async (date?: Date) => {
        try {
            //setLoadingAgendamentos(true)
            if (!authenticated) {
                return;
            }
            // const lastValues = getDataStorage('@NewIndicadorComercioApp:Agendamentos');
            // if (lastValues){
            //     setDadosIndicadorAgendamentos(lastValues);
            // }

            const data:any =  await ApiServer.BuscarDadosAgendamentos(date || lastDateFilter);
            
            if (data) {
                window.clarity("identify", data?.usuarioId);
                setDadosIndicadorAgendamentos(data);
                setDataStorage('@NewIndicadorComercioApp:Agendamentos', data);
                setVersao(data?.versaoAtual)
                setLoadingAgendamentos(false)
            } else {
                setLoadingAgendamentos(false)
                return false
            }
            setLoadingAgendamentos(false)
            return true;
        } catch (error) {
            const dados = getDataStorage('@NewIndicadorComercioApp:Agendamentos');
            setDadosIndicadorAgendamentos(dados);
            setLoadingAgendamentos(false)
            return false
        }
    };

    const getAllIndicatorsEstoque = async () => {
        try {
            //setLoadingEstoque(true)
            if (!authenticated) {
                return;
            }
            // const lastValues = getDataStorage('@NewIndicadorComercioApp:Estoque');
            // if (lastValues){
            //     const lastValuesReportOrdered = lastValues?.relatorio.sort(function(a,b) {
            //         return b.cobertura < a.cobertura ? -1 : b.cobertura > a.cobertura ? 1 : 0;
            //     })
            //     lastValues.relatorio = lastValuesReportOrdered
            //     setDadosIndicadorEstoque(lastValues);
            // }
            
            const data: any = await ApiServer.BuscarDadosGrupoIndicadoresEstoque();

            if (data) {
                const relatorioEmpresasOrdenadas = data?.relatorio.sort(function(a,b) {
                    return b.cobertura < a.cobertura ? -1 : b.cobertura > a.cobertura ? 1 : 0;
                })
                data.relatorio = relatorioEmpresasOrdenadas
                window.clarity("identify", data?.usuarioId);
                setDadosIndicadorEstoque(data);
                setDataStorage('@NewIndicadorComercioApp:Estoque', data);
                setVersao(data?.versaoAtual)
                setLoadingEstoque(false)
            } else {
                setLoadingEstoque(false)
                return false
            }
            setLoadingEstoque(false)
            return true;
        } catch (error) {
            const dados = getDataStorage('@NewIndicadorComercioApp:Estoque');
            setDadosIndicadorEstoque(dados);
            setLoadingEstoque(false)
            return false
        }


    };

    const setDataStorage = (key: string, value: any) => {
        try {
            const jsonValue = JSON.stringify(value);
            localStorage.setItem(key, jsonValue);
        } catch (e) {
        }
    };

    const getDataStorage = (key: string) => {
        try {
            const jsonValue = localStorage.getItem(key);
            return jsonValue != null ? JSON.parse(jsonValue) : null;
        } catch (e) {
        }
    };

    const handleNewPassword = async (email: string, passWord: string, NewPassWord: string) => {
        if (!email) {
            notify('Favor digitar o email.');
            return;
        }

        if (!passWord) {
            notify('Favor digitar a senha.');
            return;
        }
        if (!NewPassWord) {
            notify('Favor digitar a nova senha.');
            return;
        }
        setLoading(true);
        await ApiServer.changePassWord(email, passWord, NewPassWord).then(
            async (data) => {
                if (data) {
                    setAuthenticated(false);
                    setLoading(false);
                }
            },
            (error) => {
                setAuthenticated(false);
                setLoading(false);
            },
        );
    };

    return (
        <AppStateContext.Provider value={{
            dadosIndicadorReceitas,
            dadosIndicadorEstoque,
            dadosIndicadorAgendamentos,
            loading: loading,
            loadingReceitas,
            loadingEstoque,
            loadingAgendamentos,
            date: lastDateFilter,
            versao,
            handleNewPassword,
            getAllIndicatorsReceitas,
            getAllIndicatorsEstoque,
            getAllIndicatorsAgendamentos,
            setlastDateFilter,
            isTokenFound,
            setTokenFound,
        }}>
            {props.children}
        </AppStateContext.Provider>
    );
}
