import styled from "styled-components"
import { colors, DefaultColors } from "../../utils/colors"

export const CardComponent = styled.div`
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 50%;
    min-height: 110px;
    padding-bottom: 10px;

    &:nth-child(2){
        position: relative;
        //width: 40%;
        &:after{
            content: "";
            border-right: 1px dashed ${DefaultColors.borderColor};
            height: 100%;
            width: 1px;
            display: inline-block;
            position: absolute;
            right: 0;
        }
        &:before{
            content: "";
            border-left: 1px dashed ${DefaultColors.borderColor};
            height: 100%;
            width: 1px;
            display: inline-block;
            position: absolute;
            left: 0;
        }
    }
    small{
        &.label{
            display: block;
            font-size: 12px;
            color: ${colors.blue_100};
            font-weight: 600;
            text-transform: uppercase;
            margin: 5px 0;
            &+.number{
                span{
                    font-size: 20px;
                }
            }
        }
    }
    span{
        &.label{
            color: ${colors.blue_100};
            font-weight: 600;
            font-size: 12px;
            text-transform: uppercase;
            margin: 5px 0;
        }
        &.number{
            color: #FFFFFF;
            letter-spacing: -1.5px;
            margin: 0;
            display: flex;
            position: relative;
            span{
                line-height: 20px;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                &+span{
                    font-size: 15px;
                    margin-left: 5px;
                }
            }
            &.number-big{
                display: flex;
                flex-direction: column;
                margin-top: 5px;
                span{
                    letter-spacing: -2px;
                    font-size: 28px;
                    font-weight: 500;
                    display: block;
                    line-height: 32px;
                    &+span{
                        font-size: 18px;
                    }
                }
            }
            &+.label{
                margin-top: 15px;
            }
        }
        
    }
`
