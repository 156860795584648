import Jeep from '../assets/images/marcas/jeep.svg'
import Mercedes from '../assets/images/marcas/mercedes.svg'
import Toyota from '../assets/images/marcas/toyota.svg'
import Byd from '../assets/images/marcas/byd.svg'
import Ram from '../assets/images/marcas/dodge-ram.svg'
import GoDrive from '../assets/images/marcas/godrive.svg'
import NewHolland from '../assets/images/marcas/new-holland.svg'

export function toDate(dateStr: string | number | Date | undefined) {
    if (!dateStr)
        return "";

    // let milliseconds = new Date(dateStr).valueOf();  
    // let newDate = new Date(milliseconds - 1.08e+7);  
    let newDate = new Date(dateStr);
    const date = controlNumber(newDate.getDate().toString()) + "/" + controlNumber((newDate.getMonth() + 1).toString());
    const hour = newDate.getHours();
    let minutes = newDate.getMinutes();

    let minuteFinish = "00";
    let hourFinish = "00";
    if (minutes >= 0 && minutes < 10)
        minuteFinish = "0" + minutes
    else
        minuteFinish = String(minutes);

    if (hour >= 0 && hour < 10)
        hourFinish = "0" + hour
    else
        hourFinish = String(hour);

    return date + " " + hourFinish + ":" + minuteFinish;
}

export function monthName(date: any): string {
    if (!date) {
        return '';
    }
    const dateNew = new Date(date)

    const month = dateNew.toLocaleString('pt-BR', { month: 'long' });

    return month;
}


function controlNumber(value: string): string {
    if (value) {
        return value.length > 1 ? value : 0 + value;
    }
    return "";
}


export function convertValueToM(value: number): string {
    let valueFinish = '0';
    if (!value) {
        valueFinish = '0'
    }
    else if (value.toFixed(0).length > 4) {
        valueFinish = (value / Math.pow(10, 6)).toFixed(2) + 'M';
    }
    else if (value.toFixed(0).length === 4) {
        valueFinish = (value / Math.pow(10, 3)).toFixed(2) + 'K';
    }
    else if (value.toFixed(0).length <= 3) {
        valueFinish = value.toFixed(2);
    };

    return valueFinish;
}

export function tipeInfo(value: number): string {
    //console.log(value, value.toFixed(0).length)
    let valueFinish = '';
    if (!value) {
        valueFinish = ''
    }
    else if (value.toFixed(0).length > 4) {
        valueFinish = 'M';
    }
    else if (value.toFixed(0).length === 4) {
        valueFinish = 'K';
    }
    else if (value.toFixed(0).length <= 3) {
        const valor = value.toString().split(".")
        if(valor[1] === undefined){
            valueFinish = `,00`;
        }else{
            valueFinish = `,${valor[1]}`;
        }
    };

    return valueFinish;
}

export function convertValue(value: number): string {
    let valueFinish = 0;
    if (!value) {
        valueFinish = 0
    }
    else if (value.toFixed(0).length > 4) {
        valueFinish = ajuste(value, 6, 2)
    }
    else if (value.toFixed(0).length === 4) {
        valueFinish = ajuste(value, 3, 2)
    }
    else if (value.toFixed(0).length <= 3) {
        valueFinish = value;
    };

    return String(valueFinish);
}
export function convertValueToMilhar(value?: number): string {
    if (!value) {
        value = 0
    }
    let valueFinish = (value / 1000).toFixed(2);
    const formatedValue = Intl.NumberFormat('pt-BR').format(+valueFinish)

    return formatedValue;
}

export function formatNumber(value?: number): string {
    if (!value) {
        value = 0
    }
    const formatedValue = Intl.NumberFormat('pt-BR').format(value)
    return formatedValue;
}

function ajuste(value: number, potencia: number, casas: number) {
    const baseValue = (value / Math.pow(10, potencia));
    const og = Math.pow(10, casas)
    return Math.floor(baseValue * og) / og;
}


export const getLogo = (marca: string) => {
    const newMarca = marca?.toUpperCase()
    switch(newMarca){
        case 'TOYOTA':
            return Toyota;
        case 'MBB':
            return Mercedes;
        case 'RAM':
            return Ram;
        case 'MERCEDES':
            return Mercedes;
        case 'BYD':
            return Byd;
        case 'JEEP':
            return Jeep;
        case 'GODRIVE':
            return GoDrive;
        case 'VITÓRIA MOTORS':
            return Mercedes;
        case 'SAVANA':
            return Mercedes;
        case 'VITÓRIA DIESEL':
            return Mercedes;
        case 'KURUMÁ / OSAKA / KYOTO':
            return Toyota;
        case 'AZUL AGRO':
            return NewHolland;
    }
}