import { useContext, useState } from "react";
import { isFutureMonth, isPastMonth } from "../../utils/fn";
import { ContainerCalendar, DateSelect, IconCalendar } from "./style";
import { AppStateContext } from "../../contexts/DataContext";
import { Box } from "@chakra-ui/react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface CalendarFilterProps{
    getByDate: any
}

export default function CalendarFilter({getByDate}: CalendarFilterProps){
    
    const {
        getAllIndicatorsReceitas,
        getAllIndicatorsAgendamentos,
        setlastDateFilter,
        date,
        versao,
        loading,
    } = useContext(AppStateContext);
    
    const [dataFiltro, setDataFiltro] = useState(date || new Date())

    const maxDate: Date = new Date(new Date().setDate(28));

    return (
        <ContainerCalendar className="calendar-filter">
            <IconCalendar />
            <DateSelect
                selected={dataFiltro}
                onChange={(date) => getByDate(date, getAllIndicatorsAgendamentos, getAllIndicatorsReceitas, setlastDateFilter, setDataFiltro, versao)}
                dateFormat="MM/yyyy"
                locale="ptBr"
                showMonthYearPicker
                maxDate={maxDate}
                minDate={new Date("02-28-2022")}
                filterDate={(date) => isFutureMonth(date) || isPastMonth(date)}
            />
            {loading &&
                <Box>
                    <AiOutlineLoading3Quarters color="#4472C4" size={14} className="rotating" />
                </Box>
            }
        </ContainerCalendar>
    )
}