import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DefaultColors } from '../utils/colors';
import { FiArrowLeft, FiInfo } from "react-icons/fi";


export interface IHeaderProps{
  route?: string;
  title: string;
  children?: any;
  loadingComponent?: any;
}

export default function Header({route, title, children, loadingComponent}: IHeaderProps) {
  
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsSticky(true);
        headerRef.current?.classList.add("sticky")
      } else {
        setIsSticky(false);
        headerRef.current?.classList.remove("sticky")
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
    {/* <div className="header" id="myHeader">
      <h2>My Header</h2>
    </div> */}
    { 
      route ? 
      (
        <ContainerLink  to={route} >
                <Col id="col1">
                  <Icon />
                  <Title  >{title}</Title>
                  <Title  > </Title>
                </Col>
                <ColLoading id="ColLoading">
                  {loadingComponent && loadingComponent}
                </ColLoading>
        </ContainerLink>
      ): (
        <Container>
             <Col id="col2">
                <Title></Title>
                <Title>{title}</Title>
                <Title></Title>
              </Col>
              <ColLoading id="ColLoading2">
                {loadingComponent && loadingComponent}
              </ColLoading>
        </Container>
      )
      
    }
      {children}
      
    </>
  );
}


export const Info = styled(FiInfo)`
  color: ${DefaultColors.label};
  font-size: 1rem;
  left: 0 ;
  cursor: pointer;
`
export const ContainerLink = styled(Link)`
  /* padding: 0.5rem 0.3rem ; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  // background-color: ${DefaultColors.backgroundColor};
  
` 
export const Container = styled.div`
  /* padding: .5rem 0.3rem ; */
  display: flex;
  width: 100%;
  /* max-width: 350px; */
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  // background-color: ${DefaultColors.backgroundColor};
` 
export const ContainerChildren = styled.div`
  /* padding: 0.5rem 0.3rem ; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  background-color: ${DefaultColors.backgroundColor};
` 

export const Col = styled.div`
    display: flex;
    width: 100%;
    flex-direction:  row ;
    align-items: center ;
    justify-content: space-between ;
`;
export const ColLoading = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end ; 
`;

export const Icon = styled(FiArrowLeft)`
    color: ${DefaultColors.label};
    font-size: 1rem;
    left: 0 
`;

export const IconNull = styled(FiArrowLeft)`
    visibility: hidden;
    font-size: 1rem;
    left: 0 
`;
export const Title = styled.span`
    color: ${DefaultColors.label};
    font-size: 1rem; 
`; 