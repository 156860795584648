import styled from 'styled-components'

export const Content = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${props => props.color};
    height: 100vh;
`;

 

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    background-color: ${props => props.color};
    height: 100vh;
    padding: 0 10px
`
 


export const ContainerButton = styled.div`
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    padding-top: 1rem;
    width: 100%;
    background-color: ${props => props.color};
`
 