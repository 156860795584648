import axios from 'axios';
import { BaseDados } from '../model/relatorios/BaseDados';

export const gatewayApi = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  //baseURL: "https://comindicadores.azurewebsites.net/api",

  timeout: 10000,
  withCredentials: false,
});

const convertToDataString = (date: Date): String => {
  return new Date(date.toLocaleString("en-US", {timeZone: "America/Sao_Paulo"})).toISOString();
}

const ApiServer = {
  async BuscarDadosIndicadores(date?: Date) {
    // const sendDate = date ? date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    const sendDate = date ? date.toISOString(): new Date(new Date().setDate(10)).toISOString();
    const data = await gatewayApi.get(`v1/IndicadoresVDVendasFilial/IndicadoresComercioApp?date=${sendDate}`);
    return data;
  },

  async BuscarDadosGrupoIndicadoresReceitas(date?: Date): Promise<any> {
    // const sendDate = date ? date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    const sendDate = date ? convertToDataString(date): convertToDataString(new Date(new Date().setDate(10)));
    ApiServer.log('BuscarDadosGrupoIndicadores', `Consulta de dados na API Data ${sendDate}`, '');
    const data = await gatewayApi.get(`v1/IndicadoresDetalhados/BuscarDetalhesIndicadoresPorGrupo?date=${sendDate}`);
    return data?.data;
  },

  async BuscarDadosGrupoIndicadoresMkt(date?: Date): Promise<BaseDados | undefined> {
    // const sendDate = date ? date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    const sendDate = date ? convertToDataString(date): convertToDataString(new Date(new Date().setDate(10)));
    ApiServer.log('BuscarDadosGrupoIndicadoresMkt', `Consulta de dados na API Data ${sendDate}`, '');
    const data = await gatewayApi.get(`v1/IndicadoresMktDigital/IndicadoresMedia?date=${sendDate}`);

    return data?.data;
  },

  async BuscarDadosGrupoIndicadoresEstoque(): Promise<BaseDados | undefined> {
    ApiServer.log('BuscarDadosGrupoIndicadoresEstoque', 'Consulta de dados na API', '');
    const data = await gatewayApi.get(`v1/Estoque/IndicadorEstoque`);
    return data?.data;
  },

  async BuscarDadosAgendamentos(date?: Date): Promise<BaseDados | undefined> {
    // const sendDate = date ? date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    const sendDate = date ? convertToDataString(date): convertToDataString(new Date(new Date().setDate(10)));
    ApiServer.log('BuscarDadosAgendamentos', `Consulta de dados na API Data ${sendDate}`, '');
    const data = await gatewayApi.get(`v1/AgendamentoOficina?data=${sendDate}`);
    //const data = await gatewayApi.get(`v1/AgendamentoOficina?date=2023-02-01`);

    return data?.data;
  },

  async login(email: string, password: string) {
    try {
      const loginDto = { email, password };
      const data: any = await gatewayApi.post('v1/User/login', loginDto);
      return data;
    } catch (error: any) {
      return { error: error?.response?.data}
    }
  },

  async logoff() {
    const data: any = await gatewayApi.post('v1/User/logoff');
    return data;
  },

  async changePassWord(email: string, oldPassword: string, newPassword: string) {
    const data: any = await gatewayApi.post('v1/User/alterarSenha', { email: email, password: oldPassword, newPassword: newPassword });
    return data;
  },

  async setToken(token: any) {
    gatewayApi.defaults.headers.Token = token;
  },

  async postMetas(metas: Array<Object>){
    const data = await gatewayApi.post(`v1/Estoque/DefinirMetaPorEmpresaFamilia`, metas);
    return data
  },

  log(contexto: string, acao: string, informacoesAdicionais: string, versao?: string) {
    const data = {
      contexto,
      acao,
      informacoesAdicionais,
      versao: versao || 'N/I',
    };
    gatewayApi.post('v1/Log', data);
  },
};



// gatewayApi.interceptors.request.use(
//   async (config: any) => {
//     return config;
//   },
//   (  error: any) => {
//     return Promise.reject(error);
//   },
// );

// gatewayApi.interceptors.response.use(
//   (  response: { data: any; }) => {
//     return response.data;
//   },
//   (  error: any) => {
//     interceptorError(error);
//     return Promise.reject(error);
//   },
// );

// async function interceptorError(error: any) {

//   const response = error?.response;
//   const data = response?.data;
//   if (
//     error?.request?._hasError === true &&
//     error?.request?._response.includes('connect')
//   ) {
//     notify(
//       'Sem conexão à internet',
//     );
//     // ApiServer.log('Aplicativo', 'erro_request', 'Não foi possível conectar aos nossos servidores. Sem conexão à internet')
//   } else {
//     if (response?.status === 400 && data) {
//       notify(data);
//     } else if (response?.status === 401) {
//       // ApiServer.log('Aplicativo', 'erro_request', '401')
//       if (gatewayApi?.defaults?.headers?.Token) {
//         gatewayApi.defaults.headers.Token = null;
//       }
//      localStorage.removeItem('@autenticatedLogin:AnalyticsCOM');

//     } else if (response?.status === 404) {
//       // ApiServer.log('Aplicativo', 'request', 'Dados não encontrados para esse período')
//       notify("Dados não encontrados para esse período")
//     } else if (!response || response?.status > 404) {
//       // ApiServer.log('Aplicativo', 'erro_request', response?.status + ' Erro interno! Favor procure o suporte ou administrador do sistema.')
//     }
//   }
// }

export default ApiServer;
