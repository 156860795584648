import './assets/sass/app.scss';
import { ChakraProvider } from '@chakra-ui/react'
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthProvider';
import { AppStateProvider } from './contexts/DataContext';

import { ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    clarity: any;
  }
}

function App() {

  useEffect(() => {
    window.clarity("start", "e8llv8kjgx");
  }, []);

  

  // const onShowNotificationClicked = () => {
  //   setNotification({title: "Notification", body: "This is a test notification"})
  //   setShow(true);
  // }

  return (
    <ChakraProvider>
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          />
        <AppStateProvider>
            <BrowserRouter> 
                <Routes /> 
            </BrowserRouter>
        </AppStateProvider>
      </AuthProvider>
    </ChakraProvider >
  );
}

export default App;
